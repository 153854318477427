<template>
  <div>
    <v-data-table :loading="isLoading('get:kitequipamento')" :items="tableData" :search="search" :headers="headers" :items-per-page="-1" height="600px" fixed-header>
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-btn class="mr-4 elevation-0" text @click="atualzar"> <v-icon left>mdi-reload</v-icon> Atualizar  </v-btn>
            <v-btn color="success" class="elevation-0" @click="dialog.create = true">  <v-icon left>  mdi-plus  </v-icon> Novo </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
      
    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="1000">
      <v-card>
        <v-card-title class="blod_color">
          Novo Kit:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <CriacaoNomeKit :currentItem="newItem" :loading="isLoading('post:kitequipamento')" :onSubmit="createKitEquipamento" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="1000">
      <v-card>
        <v-card-title class="blod_color">
          Editar Kit:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <EditarKit :currentItem="selectedRow" :loading="isLoading('put:kitequipamento')" :onSubmit="updateKitEquipamento" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import EditarKit from './EditarKit.vue';
import CriacaoNomeKit from './CriacaoNomeKit.vue';
import './../../../assets/css/styles.css';

export default {
  name: "kitequipamento",
  data() {
    return UtilsFunc.withCRUDUtils({
      newItem: { ativo:1 },
      headers: [
        { text: "Editar", value: "edit", width: 20 },
        { text: "Id", value: "id", width: 10 },
        { text: "Nome do Kit", value: "nome" , width: 150},
        { text: "Itens Kit", value: "equipamentoNomes" },
        { text: "Status", value: "ativo" },
      ],
      defaultSort: {
        name: 'ativo',
        isDesc: true
      }
    });
  },
  methods: {
    async getKitEquipamento() {
      const LOADING_NAME = "get:kitequipamento";
      this.setLoading(LOADING_NAME);
      try {
        // Carregar os kits
        const { data } = await api.get("/equipamentos/kitequipamentos/");
        
        // Carregar os detalhes de todos os equipamentos
        const equipamentosResponse = await api.get("/equipamentos/nomeequipamentos/");
        const equipamentosData = equipamentosResponse.data;

        // Mapeia os IDs dos equipamentos para seus respectivos nomes
        data.forEach(kit => {
          if (kit.equipamento.length) {
            kit.equipamentoNomes = kit.equipamento
              .map(id => {
                const equipamento = equipamentosData.find(equip => equip.id === id);
                return equipamento ? equipamento.nome : "Equipamento indefinido";
              })
              .join(", "); // Une os nomes por vírgula
          } else {
            kit.equipamentoNomes = "Sem equipamentos";
          }
        });

        data.sort((a, b) => {
          // Ordena por ativo (true vem antes de false)
          if (a.ativo !== b.ativo) {
            return a.ativo ? -1 : 1;
          }
          // Se ambos são ativos ou inativos, ordena por nome
          return a.nome.localeCompare(b.nome);
        });
        this.tableData = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async createKitEquipamento(fields) {
      const LOADING_NAME = "post:kitequipamento";
      this.setLoading(LOADING_NAME);
      try {
        // Assegura que os equipamentos são apenas IDs
        const payload = {
          nome: fields.nome,
          ativo: fields.ativo,
          equipamento: fields.equipamento, // Isso deve conter apenas os IDs dos equipamentos
        };

        await api.post("/equipamentos/kitequipamentos/", payload);
        await this.getKitEquipamento();
        this.dialog.create = false;
        this.$toast.success('Kit criado com sucesso!');
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateKitEquipamento(fields) {
      const LOADING_NAME = "put:kitequipamento";
      this.setLoading(LOADING_NAME);
      try {
        // Assegura que os equipamentos são apenas IDs
        const payload = {
          nome: fields.nome,
          ativo: fields.ativo,
          equipamento: fields.equipamento, // IDs dos equipamentos
        };

        await api.put(`/equipamentos/kitequipamento/${fields.id}/`, payload);
        await this.getKitEquipamento();
        this.dialog.update = false;
        this.$toast.success('Kit atualizado com sucesso!');
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    atualzar(){
      this.getKitEquipamento()
      this.search = '';
    },

  },
  mounted() {
    this.getKitEquipamento();
  },
  components: { EditarKit, CriacaoNomeKit }
}
</script>
